// 询价解析
import buyer_dom_white_enquiryPnrTextParse from "@/lib/data-service/flight/buyer_dom_white_enquiryPnrTextParse.js";
import buyer_dom_white_submitEnquiryOrder from "@/lib/data-service/flight/buyer_dom_white_submitEnquiryOrder.js";
import buyer_dom_manage_cardTypeEnum from '@/lib/data-service/flight/buyer_dom_manage_cardTypeEnum'


export default {
    data() {
        var checkPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"))
            } else if (!/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(value)) {
                callback(new Error("请输入正确的手机号码"))
            } else {
                callback()
            }
        };
        return {
            loading: false,
            rule: {
                importPnr: [
                    { required: true, message: '请输入PNR', trigger: 'blur' },
                ],
                contactName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                ],
                contactPhone: [
                    {validator: checkPhone, required: true, trigger: 'blur' },
                ],
            },
            form: {
                flightType: 1,
                importPnr: '',
                deptTime: '2022-02-11',
                passengerType: 1,
                passengers: [],
                passengerList:[],
                segmentList:[],
                cacheKey:''
            },
            cardTypeOptions: [],
            options: [
                {value: 1, label: '成人'},
                {value: 2, label: '儿童'}

            ],
           submitLoading:false,
        }
    },
    components: {},
    methods: {
        init(){
            const __this = this;
            this.loading=true;
            const cacheKey = __this.$route.query.id;

            console.log("参数：" + cacheKey);
            let param = {
                "cacheKey":cacheKey
            }
            let __form = {}
            buyer_dom_white_enquiryPnrTextParse(param).then(data => {
                console.log(data);
                __form.importPnr=data.pnr;
                __form.flightType=data.flightType;
                __form.passengers=[];
                // debugger;
                for (let i = 1; i<= data.passengerList.length;i++){
                    let p = data.passengerList[i-1];
                    p.idx =i;
                    p.isSaveFrequent=0;
                    __form.passengers.push(p);
                }

                __form.segmentList=data.segmentList;
                __form.cacheKey = cacheKey;
                __form.contactPhone = data.contactPhone;
                __this.form=__form;
                console.log('__this.form', __this.form)
            }).finally(() => {
                __this.loading=false;
            });

        },
        _checkTime(rule, value, callback) {
            if (value.indexOf('null') > -1) {
                callback(new Error('请返回检查时间是否填写正确'));
            } else {
                return callback();
            }
        },
        checkForm() {
            const _this = this;
            return new Promise((resolve) => {
                _this.$refs.form.validate((valid) => {
                    resolve(valid)
                });
            })
        },
        async submit(){
            console.log(this.form);
            let checkResult = await this.checkForm();
            if (!checkResult) {
                return
            }
            const __this = this;
            __this.submitLoading = true;
            let param = __this.form;
            buyer_dom_white_submitEnquiryOrder(param).then(data => {
                this.$message({
                    showClose: true,
                    message: '提交成功'
                });

                // TODO 跳转页面
                this.$router.push({
                    name: 'air-ticket-admin-inquiry-order-list'
                })
            }).finally(() => {
                __this.submitLoading = false;
            });

        },
        back() {
            this.$router.go(-1)
        },
        getCardTypeEnum() {
            buyer_dom_manage_cardTypeEnum().then(res => {
                this.cardTypeOptions = res.cardTypes
            })
        },
        checkPhone(rule, value, callback) {
            if (value === "") {
                callback(new Error("请输入正确的手机号码"))
            } else if (!/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(value)) {
                callback(new Error("请输入正确的手机号码"))
            } else {
                callback()
            }
        },
    },
    created() {
    },
    mounted() {
        this.getCardTypeEnum();
        this.init();
    },
    activated() {

    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {}

}
