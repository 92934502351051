// buyer_dom_white_flightPrice 航班报价查询 http://192.168.0.33:8985/doc.html#/yinzhilv-flight/buyer-dom-white-controller/flightPriceUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const buyer_dom_white_submitEnquiryOrder = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix:'/buyer/dom/white/submitEnquiryOrder',
        data:data,
        enable_error_alert: true // 临时隐藏
    }

    return __request(pParameter)
}
export default buyer_dom_white_submitEnquiryOrder
